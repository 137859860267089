.imgDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

// .imgWrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
// }

.img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.p {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
