.nav_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  z-index: 4;
  border: none;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #fff;
}
.nav_btn > svg {
  fill: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.nav_btn_left {
  left: 0;
}
.nav_btn_right {
  right: 0;
}