.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Wrapper {
  max-width: 550px;
  width: 100%;

  .TopBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 40px 25px 40px;
    margin-bottom: 10px;
  }
}

.h1 {
  margin: 0;
  font-weight: 600;
  font-size: 25px;
  color: black;
  text-align: center;
  padding-bottom: 10px;
  margin-top: 10px;
}
.h2 {
  margin: 0;
  padding-top: 50px;
  font-size: 30px;
  color: #003c93;
  text-align: center;
  padding-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.Form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  justify-items: center;
  .topborder {
    margin-top: 2px !important;
    padding-top: 20px;
    border-top: 1px solid #003c93;
  }
  .buttonflex {
    display: flex;
    justify-content: center;
  }
}
.Input {
  width: 100%;
  border-radius: 3px;
  padding: 13px;
  background-color: #fafafa;
  border: 0.5px solid rgb(219, 219, 219);

  box-sizing: border-box;
  // max-width: 0px;
}
.Input:focus {
  outline-color: #003c93;
}

.p {
  color: #003c93;
}

.p::before {
  display: inline;
  // content: '⚠ ';
}

.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.input:disabled {
  opacity: 0.4;
}

.checkButton {
  margin-left: 10px;
  padding: 5px;
  font-size: 15px;
  color: #003c93;

  border-radius: 3px;
  border: 1px solid #003c93;
  width: 90px;
}
.flexContainer {
  display: flex;
  justify-content: space-around;
  .genderContainer {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;

    .genderlabel {
      margin-left: 10px;
      // margin-bottom: 30px;
    }
  }
}
.flexSelectContainer {
  display: flex;
  justify-content: space-around;

  .selectContainer {
    // white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .selectlabel {
      margin-bottom: 10px;
      // margin-bottom: 30px;
    }
    .selectPath {
      border: 1px solid #999;
      border-radius: 3px;
      &:focus {
        border-color: #003c93;
        outline: none;
      }
    }
  }
}
.flexLangContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .langContainer {
    display: flex;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}
.radioMargin {
  // margin-right: 20px;
  // margin-bottom: 13px;
  margin-top: 20px;
}

.errors {
  display: block;
}

.Button {
  width: 100%;
  border: none;
  border-radius: 3px;
  margin-top: 12px;
  background-color: #003c93;
  color: white;
  text-align: center;
  padding: 8px 0px;
  font-weight: bold;
  max-width: 350px;
}
.label {
  margin-top: 24px;
  margin-bottom: 14px;
  font-weight: 600;
}
input[type='radio'] {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #999;
  appearance: none;
  cursor: pointer;
  margin-right: 5px;
}
input[type='radio']:checked {
  border: 6px solid #003c93;
}
input[type='checkbox'] {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #999;
  appearance: none;
  cursor: pointer;
  margin-top: 3px;
  margin-right: 10px;
}
input[type='checkbox']:checked {
  border: 6px solid #003c93;
}
.errors {
  margin-top: 15px;
  color: #003c93;
}
.question {
  border-bottom: 1px solid #003c93;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.buttonContainer {
  margin: 40px 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
.answer {
  font-size: 16px;
}
.h6 {
  margin-top: 30px;
  font-size: 12px;
}
