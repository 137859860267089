.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.Wrapper {
  max-width: 380px;
  width: 100%;

  .TopBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 40px 25px 40px;
    margin-bottom: 10px;
  }
}
.Form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  justify-items: center;
  align-items: center;
}
.IdPassword {
  width: 100%;
  border-radius: 3px;
  padding: 13px;
  background-color: #fafafa;
  border: 0.5px solid rgb(219, 219, 219);
  margin-top: 7px;
  box-sizing: border-box;
  min-width: 300px;
}
.IdPassword:focus {
  outline-color: #003c93;
}
.Seperater {
  width: 100%;
  margin: 20px 0px 30px 0px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Button {
  width: 100%;
  border: none;
  border-radius: 3px;
  margin-top: 22px;
  background-color: #003c93;
  color: white;
  text-align: center;
  padding: 10px 0px;
  font-weight: bold;
  max-width: 380px;
}
